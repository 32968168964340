import { Box, Container, Flex, Grid, Heading, Text, GridItem, Image } from '@chakra-ui/react';
import React from 'react';
import Layout from '../../../components/Layout';
import SEO from '../../../components/seo';
import { ContactUsBannerWithForm, Hero, SubHero, MainServiceBanner } from '../../../components/shared';
import { useNewConstructionService } from '../../../GraphQL/Queries';
const NewConstruction = ({ location }) => {
  const data = useNewConstructionService();

  const {
    acf_text_block_title,
    acf_image_text_block,
    acf_service_info,
    acf_services_block,
    acf_quote,
  } = data.residentialServiceBy;

  return (
    <Layout location={location}>
      <SEO title="New Construction" />
      <Hero
        image={acf_service_info.background.sourceUrl}
        icon={acf_service_info.icon.sourceUrl}
        heroHeadline={acf_service_info.serviceTitle}
        heroCopy={acf_service_info.serviceShortDescription}
        ctaText="Get a quote"
        fullHeight
        mobilePosition="left"
      />
      <SubHero heading={acf_text_block_title.title} body={acf_text_block_title.content} />

      <MainServiceBanner
        image={acf_image_text_block.blockList[0].image.sourceUrl}
        title={acf_image_text_block.blockList[0].title}
        noCta
        description={acf_image_text_block.blockList[0].content.replace(/<\/?[^>]+(>|$)/g, '')}
      />
      <Container mb="4rem" mt={{ base: '1rem', md: '10rem' }} overflow="hidden">
        <Flex flexDirection={{ base: 'column', md: 'row' }} pl={{ base: '2rem', md: 'unset' }}>
          <Flex
            minW={{ base: 'unset', md: '17%' }}
            borderRight={{ base: '0', md: '2px' }}
            borderColor="primary"
            mr="2rem"
            minH="100%"
            alignItems="center"
          >
            <Heading fontSize="35px" color="primary">
              {acf_image_text_block.blockList[1].title}
            </Heading>
          </Flex>
          <Box mt={{ base: '1rem', md: 'unset' }}>
            <Text textStyle="servicePageText" pr={{ base: '2rem', md: 'unset' }}>
              {acf_image_text_block.blockList[1].content.replace(/<\/?[^>]+(>|$)/g, '')}
            </Text>
          </Box>
        </Flex>
        <Box my={{ base: '1rem', md: '3rem' }} pb="1rem" borderBottom={{ base: '0', md: '2px' }} borderColor="primary">
          <Text textStyle="servicePageText" maxW={{ base: '100%', md: '50%' }} px={{ base: '2rem', md: 'unset' }}>
            We typically follow a smooth and concise process that allows us to plan, develop, and construct your home
            project:
          </Text>
        </Box>
        <Image
          src="/images/FreeConsultation.png"
          display={{ base: 'block', md: 'none' }}
          pl="2rem"
          h="300px"
          objectFit="cover"
          clipPath="polygon(15% 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 15%)"
        />
        <Grid
          mt="2rem"
          gap={{ base: 1, md: 4 }}
          templateRows={{ base: 'repeat(5, auto)', md: 'repeat(4, 1fr)' }}
          templateColumns={{ base: '85% 8%', md: '91% 7%' }}
        >
          <Flex flexDirection={{ base: 'column', md: 'row' }} colSpan={1}>
            <Image
              display={{ base: 'none', md: 'block' }}
              maxW={{ base: '100%', md: '70%' }}
              objectFit="cover"
              src={acf_services_block.servicesList[0].mainImage.sourceUrl}
              maxH={{ md: '450px' }}
            />
            <Flex
              py={{ base: '1rem', md: 'unset' }}
              px="2.5rem"
              pr={{ md: '0' }}
              flexDirection="column"
              justifyContent="center"
              colSpan={1}
            >
              <Text
                textTransform="uppercase"
                fontWeight="bold"
                lineHeight="1.33"
                fontSize={{ base: '20px', md: 'unset' }}
              >
                {acf_services_block.servicesList[0].serviceTitle}
              </Text>
              <Text mt="1rem" textAlign="justify">
                {acf_services_block.servicesList[0].serviceDescription}
              </Text>
            </Flex>
          </Flex>
          <GridItem rowSpan={4} colSpan={1}>
            <Flex
              borderRight={{ base: '2px', md: '2px' }}
              borderColor="primary"
              flexDirection="column"
              h={{ base: '88%', md: '78%' }}
              mt={{ base: '2rem', md: '1.25rem' }}
              mb="2rem"
              position="relative"
            >
              <Box h={{ base: '32%', md: '35%' }} borderTop={{ base: 'unset', md: '2px' }} borderColor="primary">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  mt="-25px"
                  ml={{ base: '5px', md: '0' }}
                  w="50px"
                  h="50px"
                  borderRadius="100%"
                  bg="secondary"
                >
                  <Heading color="white">1</Heading>
                </Flex>
              </Box>
              <Box h={{ base: '37%', md: '38%' }} borderTop={{ base: 'unset', md: '2px' }} borderColor="primary">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  mt={{ base: '-15px', md: '-25px' }}
                  ml={{ base: '5px', md: '0' }}
                  w="50px"
                  h="50px"
                  borderRadius="100%"
                  bg="secondary"
                >
                  <Heading color="white">2</Heading>
                </Flex>
              </Box>
              <Box h={{ base: '28%', md: '38%' }} borderTop={{ base: 'unset', md: '2px' }} borderColor="primary">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  mt={{ base: '-3rem', md: '-25px' }}
                  ml={{ base: '5px', md: '0' }}
                  w="50px"
                  h="50px"
                  borderRadius="100%"
                  bg="secondary"
                >
                  <Heading color="white">3</Heading>
                </Flex>
              </Box>

              <Box
                borderBottom={{ base: 'unset', md: '2px' }}
                borderColor="primary"
                position={{ base: 'absolute', md: 'unset' }}
                bottom={{ base: '-15px', md: 'unset' }}
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  mt={{ base: '1rem', md: 'unset' }}
                  mb={{ base: 'unset', md: '-25px' }}
                  ml={{ base: '5px', md: '0' }}
                  w="50px"
                  h="50px"
                  borderRadius="100%"
                  bg="secondary"
                >
                  <Heading color="white">4</Heading>
                </Flex>
              </Box>
            </Flex>
          </GridItem>
          <Flex flexDirection={{ base: 'column', md: 'row' }} colSpan={1}>
            <Image
              maxW={{ base: '100%', md: '70%' }}
              objectFit="cover"
              src={acf_services_block.servicesList[1].mainImage.sourceUrl}
              clipPath={{ base: 'polygon(93% 0, 100% 7%, 100% 100%, 0 100%, 0 0)', md: 'unset' }}
              h={{ base: '300px', md: 'unset' }}
              maxH={{ md: '450px' }}
            />
            <Flex
              py={{ base: '1rem', md: 'unset' }}
              pl="2.5rem"
              flexDirection="column"
              justifyContent="center"
              colSpan={1}
            >
              <Text
                textTransform="uppercase"
                fontWeight="bold"
                lineHeight="1.33"
                fontSize={{ base: '20px', md: 'unset' }}
                pt={{ base: '1rem', md: 'unset' }}
              >
                {acf_services_block.servicesList[1].serviceTitle}
              </Text>
              <Text mt="1rem" textAlign="justify">
                {acf_services_block.servicesList[1].serviceDescription}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDirection={{ base: 'column', md: 'row' }} colSpan={1}>
            <Image
              maxW={{ base: '100%', md: '70%' }}
              objectFit="cover"
              src={acf_services_block.servicesList[2].mainImage.sourceUrl}
              clipPath={{ base: 'polygon(93% 0, 100% 7%, 100% 100%, 0 100%, 0 0)', md: 'unset' }}
              h={{ base: '300px', md: 'unset' }}
              maxH={{ md: '450px' }}
            />
            <Flex
              py={{ base: '1rem', md: 'unset' }}
              px="2.5rem"
              flexDirection="column"
              justifyContent="center"
              colSpan={1}
              pr={{ md: '0' }}
            >
              <Text
                textTransform="uppercase"
                fontWeight="bold"
                lineHeight="1.33"
                fontSize={{ base: '20px', md: 'unset' }}
                pt={{ base: '1rem', md: 'unset' }}
              >
                {acf_services_block.servicesList[2].serviceTitle}
              </Text>
              <Text mt="1rem" textAlign="justify">
                {acf_services_block.servicesList[2].serviceDescription}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDirection={{ base: 'column', md: 'row' }} colSpan={1}>
            <Image
              maxW={{ base: '100%', md: '70%' }}
              objectFit="cover"
              src={acf_services_block.servicesList[3].mainImage.sourceUrl}
              clipPath={{ base: 'polygon(93% 0, 100% 7%, 100% 100%, 0 100%, 0 0)', md: 'unset' }}
              h={{ base: '300px', md: 'unset' }}
              maxH={{ md: '450px' }}
            />
            <Flex
              py={{ base: '1rem', md: 'unset' }}
              px="2.5rem"
              flexDirection="column"
              justifyContent="center"
              colSpan={1}
              pr={{ md: '0' }}
            >
              <Text
                textTransform="uppercase"
                fontWeight="bold"
                lineHeight="1.33"
                fontSize={{ base: '20px', md: 'unset' }}
                pt={{ base: '1rem', md: 'unset' }}
              >
                {acf_services_block.servicesList[3].serviceTitle}
              </Text>
              <Text mt="1rem" textAlign="justify">
                {acf_services_block.servicesList[3].serviceDescription}
              </Text>
            </Flex>
          </Flex>
        </Grid>
      </Container>

      {/* <ProjectsSection residential /> */}
      <Box mb={{ md: '15rem' }}></Box>

      <ContactUsBannerWithForm
        title={acf_quote.titleQuoteSection}
        image={acf_quote.backgroundImage.sourceUrl}
        description={acf_quote.descriptionQuote}
      />
    </Layout>
  );
};

export default NewConstruction;
